.header-title{
    color: white;
    font-weight: bolder;
    font-size:xxx-large;
}

.animated {
    animation-name: fadeIn;
    animation-duration: 5s;
    }
    
    @keyframes fadeIn {
    from {
        transform: translateY(0);
        opacity: 0;
    }
    to {
        transform: translateY(-10px);
        opacity: 1;
    }
    }

